/* app/javascript/stylesheets/application.scss */
@import "tailwindcss/base";
@import "tailwindcss/components";

/* Add any custom CSS here */
@import "tailwindcss/utilities";

.form-input {
  @apply block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 border-gray-300 rounded-md;
}

.form-input-todo {
  @apply block w-full dark:bg-dark-secondary-bg focus:ring-0 focus:ring-gray-100 border-none focus:shadow-inner focus:border-gray-100 dark:focus:border-dark-background  border-0 border-transparent rounded-md my-2 focus:bg-gray-50 dark:focus:bg-dark-tertiary-bg dark:text-gray-50;
}

.form-button {
  @apply w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm font-medium text-white bg-gray-700 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 cursor-pointer;
}

.app-button {
  @apply p-2 rounded-full border-0 focus:outline-none focus:ring-0 focus:ring-inset active:shadow-inner;
}

.app-button-todo {
  @apply flex justify-center items-center py-2 px-1.5 sm:px-6 md:px-7 lg:px-8 xl:px-2 2xl:px-4 mx-0.5 sm:mx-2 xl:mx-0.5 2xl:mx-2 rounded-md shadow font-medium text-white dark:text-dark-tertiary-bg bg-gray-700 dark:bg-gray-50 hover:bg-gray-900 dark:hover:bg-gray-200 focus:outline-none focus:ring-1 focus:ring-inset focus:ring-gray-900 cursor-pointer focus:shadow-inner;
}

.pomodoro-action {
  @apply flex justify-center items-center py-2 w-28 mx-2 rounded-md shadow font-medium text-white dark:text-dark-tertiary-bg bg-gray-700 dark:bg-gray-50 hover:bg-gray-900 dark:hover:bg-gray-200 focus:outline-none focus:ring-1 focus:ring-inset focus:ring-gray-900 cursor-pointer focus:shadow-inner text-center;
}

.pomodoro-buttons {
  @apply shadow p-2 mx-3 rounded-md border-0 focus:outline-none focus:ring-0 focus:ring-inset active:shadow-inner dark:text-gray-50 dark:bg-dark-fourth-bg active:bg-gray-50 dark:active:bg-dark-tertiary-bg w-8 h-8 flex flex-row justify-center items-center font-semibold text-lg;
}

section:disabled {
  @apply hidden;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #c0c1c2 #f3f4f6;
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: #f3f4f6;
  border-radius: 25px;
}

*::-webkit-scrollbar-thumb {
  background-color: #c0c1c2;
  border-radius: 25px;
  border: transparent;
}

// Music player
.wheel {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  max-height: 750px !important;
  max-width: 750px !important;
  -webkit-transform: scale(0.94);
  -ms-transform: scale(0.94);
  transform: scale(0.94);
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.wheel-circle {
  border-radius: 50%;
  background: #c6c7cc;
  height: 80px;
  z-index: -1;
  width: 80px;
}

.paused-animation {
  animation-play-state: paused;
}
